<template>
  <v-container fluid>
    <running-dialog
      message="Getting billing records. Please wait..."
      :showDialog.sync="gettingBillingRecords"
    ></running-dialog>
    <error-dialog
      message="Billing Records Error! Try again."
      :showDialog.sync="showBillingRecordsErrorDialog"
    ></error-dialog>
    <change-account-status
      :showDialog.sync="showChangeStatusDialog"
      :itemToChange="itemToChange"
      @refresh="refresh"
    ></change-account-status>
    <v-card>
      <v-card-title> Invoice History </v-card-title>
      <v-data-table
        fixed-header
        class="scrollable-table__large"
        :headers="headers"
        :items="invoiceItems"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalInvoiceItems"
        item-key="id"
        :single-select="false"
        show-select
        v-model="selected"
        :footer-props="{
          'items-per-page-options': rowsPerPage,
        }"
      >
        <template v-slot:progress>
          <v-progress-linear
            :color="colorTheme.progress"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span class="link-text" @click="changeStatus(item)" v-on="on">
                {{ item.status }}
              </span>
            </template>
            Change status
          </v-tooltip>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span
                class="link-text"
                @click="showBillingRecords(item)"
                v-on="on"
              >
                <cell-monetary-value :value="item.amount" />
              </span>
            </template>
            Show Billing Records
          </v-tooltip>
        </template>
        <template v-slot:[`item.billingPeriod`]="{ item }">
          <span class="no-wrap">
            {{ displayPeriod(item) }}
          </span>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <cell-clipboard :text="item.id" />
        </template>
        <template v-slot:[`item.accountId`]="{ item }">
          <cell-clipboard :text="item.accountId" />
        </template>
        <template v-slot:[`item.paidByAccountId`]="{ item }">
          <cell-clipboard :text="item.paidByAccountId" />
        </template>
      </v-data-table>
      <v-row justify="space-between" class="ml-0 mr-0">
        <span>
          <v-btn
            class="mb-2 ml-2"
            :color="colorTheme.button"
            dark
            @click="back"
          >
            Back
          </v-btn>
        </span>
        <span>
          <v-btn
            class="mb-2 ml-2"
            :color="colorTheme.button"
            :dark="selected.length == 1"
            @click="viewPayouts"
            :disabled="selected.length != 1"
          >
            View Payouts
          </v-btn>
          <v-btn
            class="mb-2 ml-2 mr-2"
            :color="colorTheme.button"
            :dark="selected.length == 1"
            @click="viewStatusHistory"
            :disabled="selected.length != 1"
          >
            View Status History
          </v-btn>
        </span>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getPreviousRunInt, getNextRunInt } from "@/utils/period-util.js";
import { formatMonetaryValue, formatPeriod } from "@/utils/format-util.js";
import amplitude from 'amplitude-js'

import ChangeAccountStatus from "./dialogs/ChangeAccountStatus.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import CellMonetaryValue from "@/components/common/CellMonetaryValue.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";

export default {
  components: {
    ChangeAccountStatus,
    CellClipboard,
    CellMonetaryValue,
    ErrorDialog,
    RunningDialog,
  },
  data: () => ({
    gettingBillingRecords: false,
    showBillingRecordsErrorDialog: false,
    loading: false,
    showChangeStatusDialog: false,
    itemToChange: null,
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false,
      },
      {
        text: "Account ID",
        value: "accountId",
        sortable: false,
      },
      {
        text: "Account Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Billing Period",
        value: "billingPeriod",
        sortable: false,
      },
      {
        text: "Paid By Account ID",
        value: "paidByAccountId",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Amount",
        value: "amount",
        align: "end",
        sortable: false,
      },
      {
        text: "Billing Frequency",
        value: "billingFrequency",
        sortable: false,
      },
      {
        text: "Custodian",
        value: "custodian",
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme", "rowsPerPage"]),
    ...mapState("invoiceScreen", [
      "invoiceItems",
      "totalInvoiceItems",
      "selectedInvoices",
      "pagination",
    ]),
    ...mapState("accountStatus", ["selectedLineItems"]),
    ...mapState("billingRecordsCalculations", ["defaultPagination"]),
    options: {
      get() {
        return this.pagination;
      },
      set(newValue) {
        this.setPagination(newValue);
      },
    },
    page() {
      return this.options.page;
    },
    pageSize() {
      return this.options.itemsPerPage;
    },
    selected: {
      get() {
        return this.selectedInvoices;
      },
      set(newValue) {
        this.setSelectedInvoices(newValue);
      },
    },
  },
  watch: {
    page() {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refresh();
    },
    pageSize(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      this.refreshOrCut(val);
    },
  },
  methods: {
    ...mapMutations("invoiceScreen", [
      "setSelectedInvoices",
      "setPagination",
      "setInvoiceItems",
    ]),
    ...mapMutations("payoutScreen", ["resetPayoutsPagination"]),
    ...mapActions("invoiceScreen", ["getInvoices"]),
    ...mapActions("feeResultsRecords", ["getBillingRecordsForInvoice"]),
    ...mapMutations("billingRecordsCalculations", [
      "setBillingRecordsPagination"
    ]),
    async refreshOrCut(pageSize) {
      let array = this.invoiceItems;
      if (pageSize > array.length) {
        await this.refresh();
      } else {
        this.setInvoiceItems(this.invoiceItems.slice(0, pageSize));
      }
    },
    async refresh() {
      this.loading = true;
      await this.getInvoices({
        page: this.page,
        pageSize: this.pageSize,
      });
      this.loading = false;
    },
    viewPayouts() {
      this.resetPayoutsPagination();
      this.$router.push({ name: "PayoutScreen" });
    },
    viewStatusHistory() {
      this.$router.push({ name: "FullStatusHistory" });
      amplitude.getInstance().logEvent("Invoice History [View Status History]");
    },
    back() {
      this.$router.push({ name: "AccountStatuses" });
    },
    changeStatus(item) {
      this.showChangeStatusDialog = true;
      this.itemToChange = item;
    },
    async showBillingRecords(item) {
      this.gettingBillingRecords = true;
      let accounts = [
        { id: item.accountId, billingRunType: item.billingRunType }
      ];
      let result = await this.getBillingRecordsForInvoice({
        page: this.defaultPagination.page,
        pageSize: this.defaultPagination.itemsPerPage,
        billingPeriod:
          item.billingRunType == "Regular"
            ? item.billingPeriod
            : getPreviousRunInt(item.billingPeriod),
        accounts: accounts
      });
      this.gettingBillingRecords = false;
      if (result) {
        this.setBillingRecordsPagination(this.defaultPagination);
        this.$router.push({
          name: "FeeResultsRecords",
          params: { previousScreen: "InvoiceScreen" }
        });
      } else {
        this.showBillingRecordsErrorDialog = true;
      }
    },
    formatMonetaryValue(number) {
      return formatMonetaryValue(number);
    },
    formatPeriod(period) {
      return formatPeriod(period);
    },
    displayPeriod(item) {
      if (item.billingRunType == "Regular") {
        return formatPeriod(getNextRunInt(item.billingPeriod));
      } else {
        return formatPeriod(item.billingPeriod);
      }
    },
  },
  async mounted() {
    if (this.selectedLineItems.length == 0) {
      this.$router.replace({ name: "AccountStatus" });
    } else {
      this.selected = [];
      this.setInvoiceItems([]);
      await this.refresh();
    }
  },
};
</script>
