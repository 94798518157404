<template>
  <v-container>
    <running-dialog
      message="Updating status. Please wait..."
      :showDialog.sync="updatingStatuses"
    ></running-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updateSuccess"
    ></success-dialog>
    <error-dialog
      :message="errorMessage"
      :showDialog.sync="showErrorMessage"
    ></error-dialog>
    <change-account-statuses
      :showDialog.sync="show"
      :theme="colorTheme"
      multipleInvoices="false"
      :newInvoiceStatus.sync="newStatus"
      :note.sync="cancelationNote"
      :dropdownItems="billingStatusTypes"
      :save="save"
      :resetNote="resetNote"
      :changedNote="changedNote"
      :changedStatus="changedStatus"
    ></change-account-statuses>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import ChangeAccountStatuses from "./ChangeAccountStatuses.vue";

export default {
  components: {
    RunningDialog,
    SuccessDialog,
    ErrorDialog,
    ChangeAccountStatuses
  },
  props: ["showDialog", "itemToChange"],
  emits: ["update:showDialog", "refresh"],
  data: () => ({
    cancelationNote: null,
    oldCancelationNote: null,
    currentStatus: null,
    newStatus: null,
    updatingStatuses: false,
    updateSuccess: false,
    showErrorMessage: false,
    errorMessage: "",
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    },
    changedStatus() {
      if (
        this.newStatus &&
        this.newStatus.value !== null &&
        this.currentStatus.value !== this.newStatus.value
      ) {
        return true;
      }
      return false;
    },
    changedNote() {
      if (!this.cancelationNote || this.cancelationNote.trim().length === 0) {
        return false;
      }
      return this.cancelationNote.trim() !== this.oldCancelationNote;
    },
    ...mapState("accountStatus", ["billingStatusTypes"]),
  },
  watch: {
    show(val) {
      if (val) {
        this.setStatus();
        this.setCancelationNote();
        this.newStatus = this.currentStatus;
      }
    }
  },
  methods: {
    ...mapActions("accountStatus", ["updateStatus"]),
    setStatus() {
      if (this.itemToChange != null) {
        this.currentStatus = { value: this.itemToChange.status };
        let i = 0;
        let status = null;
        while (i < this.billingStatusTypes.length && status == null) {
          let statusType = this.billingStatusTypes[i];
          if (statusType.value === this.currentStatus.value) {
            status = statusType;
          }
          i++;
        }
        this.currentStatus = status;
      } else {
        this.currentStatus = null;
      }
    },
    setCancelationNote() {
      if (this.itemToChange.note) {
        this.oldCancelationNote = this.itemToChange.note.trim();
        this.cancelationNote = this.itemToChange.note.trim();
      } else {
        this.oldCancelationNote = null;
        this.cancelationNote = null;
      }
    },
    async save() {
      this.updatingStatuses = true;
      let result = await this.updateStatus({
        id: this.itemToChange.id,
        newStatus: this.newStatus.value,
        note: this.cancelationNote
      });
      this.updatingStatuses = false;

      if (result) {
        this.updateSuccess = true;
        this.$emit("refresh");
      } else {
        this.showErrorMessage = true;
        this.errorMessage = "Update failed";
      }
    },
    resetNote() {
      this.cancelationNote = null;
    },
  }
};
</script>
