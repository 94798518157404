var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('running-dialog',{attrs:{"message":"Getting billing records. Please wait...","showDialog":_vm.gettingBillingRecords},on:{"update:showDialog":function($event){_vm.gettingBillingRecords=$event},"update:show-dialog":function($event){_vm.gettingBillingRecords=$event}}}),_c('error-dialog',{attrs:{"message":"Billing Records Error! Try again.","showDialog":_vm.showBillingRecordsErrorDialog},on:{"update:showDialog":function($event){_vm.showBillingRecordsErrorDialog=$event},"update:show-dialog":function($event){_vm.showBillingRecordsErrorDialog=$event}}}),_c('change-account-status',{attrs:{"showDialog":_vm.showChangeStatusDialog,"itemToChange":_vm.itemToChange},on:{"update:showDialog":function($event){_vm.showChangeStatusDialog=$event},"update:show-dialog":function($event){_vm.showChangeStatusDialog=$event},"refresh":_vm.refresh}}),_c('v-card',[_c('v-card-title',[_vm._v(" Invoice History ")]),_c('v-data-table',{staticClass:"scrollable-table__large",attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.invoiceItems,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalInvoiceItems,"item-key":"id","single-select":false,"show-select":"","footer-props":{
        'items-per-page-options': _vm.rowsPerPage,
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":_vm.colorTheme.progress,"indeterminate":""}})]},proxy:true},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"link-text",on:{"click":function($event){return _vm.changeStatus(item)}}},on),[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)},[_vm._v(" Change status ")])]}},{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"link-text",on:{"click":function($event){return _vm.showBillingRecords(item)}}},on),[_c('cell-monetary-value',{attrs:{"value":item.amount}})],1)]}}],null,true)},[_vm._v(" Show Billing Records ")])]}},{key:"item.billingPeriod",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(_vm.displayPeriod(item))+" ")])]}},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.id}})]}},{key:"item.accountId",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.accountId}})]}},{key:"item.paidByAccountId",fn:function(ref){
      var item = ref.item;
return [_c('cell-clipboard',{attrs:{"text":item.paidByAccountId}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-row',{staticClass:"ml-0 mr-0",attrs:{"justify":"space-between"}},[_c('span',[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":_vm.colorTheme.button,"dark":""},on:{"click":_vm.back}},[_vm._v(" Back ")])],1),_c('span',[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":_vm.colorTheme.button,"dark":_vm.selected.length == 1,"disabled":_vm.selected.length != 1},on:{"click":_vm.viewPayouts}},[_vm._v(" View Payouts ")]),_c('v-btn',{staticClass:"mb-2 ml-2 mr-2",attrs:{"color":_vm.colorTheme.button,"dark":_vm.selected.length == 1,"disabled":_vm.selected.length != 1},on:{"click":_vm.viewStatusHistory}},[_vm._v(" View Status History ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }